import styled from '@emotion/styled';
import tw from 'twin.macro';

const TitleStyled = styled.h1`
  ${tw`text-center text-4xl text-primary-900 mb-5 mt-5 font-thin leading-tight`}

  & span {
    ${tw`font-bold`}
  }
`;

const PStyled = styled.p`
  ${tw`text-gray-600 text-center pb-3 font-thin leading-6`}
`;

export { TitleStyled, PStyled };
