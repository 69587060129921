import React, { ChangeEvent, useContext } from 'react';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';

import creditCardService from '../../services/endpoints';

import { CreditcardContext } from '../../context/creditcard.context';
import { FormProps, RenderInputProps } from './models';
import pages from '../../constants/pages.json';

import { Input, Select } from '../inputs';
import { ArticleStyled, SubmitStyled, FormCvuStyled } from './styles';

import { convertFromAppToCvu } from '../../utils/converts';
import { createMask, MASK_CHAR_CARD } from '../../utils/masks';
import { navigateToSuccess, navigateToFailed } from '../../utils/navigate';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../context/lang.context';
const taxTypeList = ['CUIT', 'CUIL'];

const documentTypeList = [
  { value: 'DNI', title: 'DNI' },
  { value: 'PASAPORTE', title: 'Pasaporte' },
  { value: 'CEDULA', title: 'Cédula de identificación' },
  { value: 'LIBCIV', title: 'Libreta Cívica' },
  { value: 'LIBENR', title: 'Libreta de Enrolamiento' },
];

const RenderName = ({ data, formik, showName }: RenderInputProps) => {
  const { t } = useContext(LangContext);
  if (!showName) return null;

  return (
    <ArticleStyled>
      <Input
        title={t(data).CVU.FORM.NAME}
        errorMessage={formik.errors.name}
        onChange={formik.handleChange('name')}
        onFocus={() => formik.setFieldTouched('name', false)}
        touchedInput={formik.touched.name}
        value={formik.values.name}
        mask={createMask('NAME', 40)}
        maxLength={40}
        placeholderChar={MASK_CHAR_CARD}
        guide={false}
        suffixIcon={true}
      />
    </ArticleStyled>
  );
};

const RenderLastName = ({ data, formik, showLastName }: RenderInputProps) => {
  const { t } = useContext(LangContext);
  if (!showLastName) return null;

  return (
    <ArticleStyled>
      <Input
        title={t(data).CVU.FORM.LASTNAME}
        errorMessage={formik.errors.lastname}
        onChange={formik.handleChange('lastname')}
        onFocus={() => formik.setFieldTouched('lastname', false)}
        touchedInput={formik.touched.lastname}
        value={formik.values.lastname}
        mask={createMask('NAME', 40)}
        maxLength={40}
        placeholderChar={MASK_CHAR_CARD}
        guide={false}
        suffixIcon={true}
      />
    </ArticleStyled>
  );
};

const RenderDocumentType = ({ data, formik, showDocumentType }: RenderInputProps) => {
  const { t } = useContext(LangContext);
  if (!showDocumentType) return null;

  return (
    <ArticleStyled>
      <Select
        title={t(data).CVU.FORM.DOCUMENT_TYPE}
        options={documentTypeList}
        onChange={(e: ChangeEvent<any>) => {
          formik.setFieldValue('documentType', e.target.value);
          formik.setFieldTouched('documentType', false);
          formik.setFieldValue('documentNumber', '');
        }}
        touchedInput={formik.touched.documentType}
        errorMessage={formik.errors.documentType}
        defaultOption={formik.values.documentType}
        emptySelect={t(data).CVU.FORM.EMPTY_SELECT_DOCUMENT_TYPE}
        removeDefaultValue
      />
    </ArticleStyled>
  );
};

const RenderDocumentNumber = ({ data, formik, showDocumentNumber }: RenderInputProps) => {
  const { t } = useContext(LangContext);
  if (!showDocumentNumber) return null;

  const { documentType } = formik.values;
  let maxLength = 8;
  let mask = createMask('NUMBER', maxLength);

  if (documentType === 'PASAPORTE') {
    maxLength = 9;
    mask = createMask('PASSPORT', maxLength);
  }

  return (
    <ArticleStyled>
      <Input
        title={t(data).CVU.FORM.DOCUMENT_NUMBER}
        errorMessage={formik.errors.documentNumber}
        onChange={formik.handleChange('documentNumber')}
        onFocus={() => formik.setFieldTouched('documentNumber', false)}
        touchedInput={formik.touched.documentNumber}
        value={formik.values.documentNumber}
        mask={mask}
        placeholderChar={MASK_CHAR_CARD}
        guide={false}
        suffixIcon={true}
        maxLength={maxLength}
      />
    </ArticleStyled>
  );
};

const RenderTaxType = ({ data, formik, showTaxType }: RenderInputProps) => {
  const { t } = useContext(LangContext);
  if (!showTaxType) return null;

  return (
    <ArticleStyled>
      <Select
        title={t(data).CVU.FORM.TAX_TYPE}
        options={taxTypeList}
        onChange={(e: ChangeEvent<any>) => {
          formik.setFieldValue('taxType', e.target.value);
          formik.setFieldTouched('taxType', false);
        }}
        touchedInput={formik.touched.taxType}
        errorMessage={formik.errors.taxType}
        defaultOption={formik.values.taxType}
        emptySelect={t(data).CVU.FORM.EMPTY_SELECT_TAX_TYPE}
        removeDefaultValue
      />
    </ArticleStyled>
  );
};

const RenderTaxNumber = ({ data, formik, showTaxNumber }: RenderInputProps) => {
  const { t } = useContext(LangContext);

  if (!showTaxNumber) return null;

  return (
    <ArticleStyled>
      <Input
        title={t(data).CVU.FORM.TAX_NUMBER}
        errorMessage={formik.errors.taxNumber}
        onChange={formik.handleChange('taxNumber')}
        onFocus={() => formik.setFieldTouched('taxNumber', false)}
        touchedInput={formik.touched.taxNumber}
        value={formik.values.taxNumber}
        mask={createMask('NUMBER', 11)}
        guide={false}
        suffixIcon={true}
        maxLength={11}
      />
    </ArticleStyled>
  );
};

const CvuForm = ({ token }: FormProps) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          CVU {
            TITLE_1
            TITLE_2
            TITLE_3
            DESCRIPTION
            FORM {
              NAME
              LASTNAME
              DOCUMENT_TYPE
              DOCUMENT_NUMBER
              EMPTY_SELECT_DOCUMENT_TYPE
              TAX_TYPE
              EMPTY_SELECT_TAX_TYPE
              TAX_NUMBER
              ALIAS
              SEND
              SUCCESS {
                SUCCESS_CVU_TITLE_1
                CVU
                SUCCESS_CVU_TITLE_2
                ALIAS
                SUCCESS_CVU_TITLE_3
              }
              VALIDATION {
                INVALID_NAME
                INVALID_LASTNAME
                INVALID_DOCUMENT_TYPE
                INVALID_DOCUMENT_NUMBER
                INVALID_TAXTYPE
                INVALID_TAXNUMBER_1
                INVALID_TAXNUMBER_2
                INVALID_ALIAS
                INVALID_LENGTH_ALIAS
                INVALID_COMPANY
              }
            }
            ERROR {
              TITLE_1
              TITLE_2
              DESCRIPTION_1
              DESCRIPTION_2
              ALTA_CVU
              CHAT
            }
          }
        }
      }
    }
  `);

  const { infoUser, setLoading } = useContext(CreditcardContext);
  const { name, lastname, documentNumber, documentType, taxNumber, taxType } = infoUser;
  const rules = {
    name: Yup.string().required(() => t(data).CVU.FORM.VALIDATION.INVALID_NAME),
    lastname: Yup.string().required(() => t(data).CVU.FORM.VALIDATION.INVALID_LASTNAME),
    taxType: Yup.mixed()
      .required(t(data).CVU.FORM.VALIDATION.INVALID_TAXTYPE)
      .oneOf([...taxTypeList]),
    taxNumber: Yup.string()
      .required(t(data).CVU.FORM.VALIDATION.INVALID_TAXNUMBER_2)
      .validCuitCuil(t(data).CVU.FORM.VALIDATION.INVALID_TAXNUMBER_1),
    documentType: Yup.mixed()
      .required(t(data).CVU.FORM.VALIDATION.INVALID_DOCUMENT_TYPE)
      .oneOf([...documentTypeList.map(({ value }) => value)]),
    documentNumber: Yup.lazy(() => {
      const { documentType } = formik.values;

      if (documentType === 'PASAPORTE') {
        return Yup.string()
          .required(t(data).CVU.FORM.VALIDATION.INVALID_DOCUMENT_NUMBER)
          .min(9, t(data).CVU.FORM.VALIDATION.INVALID_DOCUMENT_NUMBER);
      }

      return Yup.string()
        .required(t(data).CVU.FORM.VALIDATION.INVALID_DOCUMENT_NUMBER)
        .min(7, t(data).CVU.FORM.VALIDATION.INVALID_DOCUMENT_NUMBER);
    }),
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      lastname: '',
      taxType: '',
      taxNumber: '',
      documentNumber: '',
      documentType: '',
      alias: '',
    },
    validationSchema: Yup.object({
      ...(name === '' ? { name: rules.name } : {}),
      ...(lastname === '' ? { lastname: rules.lastname } : {}),
      ...(taxType === '' ? { taxType: rules.taxType } : {}),
      ...(taxNumber === '' ? { taxNumber: rules.taxNumber } : {}),
      ...(documentType === '' ? { documentType: rules.documentType } : {}),
      ...(documentNumber === '' ? { documentNumber: rules.documentNumber } : {}),
      alias: Yup.string()
        .min(6, t(data).CVU.FORM.VALIDATION.INVALID_LENGTH_ALIAS)
        .required(t(data).CVU.FORM.VALIDATION.INVALID_ALIAS),
    }),
    onSubmit: async (values: FormikValues) => {
      setLoading?.(true);

      try {
        const paramsRequest = convertFromAppToCvu(values);
        const { cvu, alias } = await creditCardService.postCvuInformation(paramsRequest, token);

        const state = {
          from: pages.SUCCESS_PAGE.CVU,
          cvu,
          alias,
        };

        navigateToSuccess({ state, callback: () => setLoading?.(false) })?.();
      } catch {
        const state = {
          from: pages.FAILED_PAGE.CVU,
        };
        navigateToFailed({ state, callback: () => setLoading?.(false) })?.();
      }
    },
  });

  return (
    <FormCvuStyled autoComplete="false" onSubmit={formik.handleSubmit} className="w-full">
      <RenderName data={data} formik={formik} showName={name === ''} />
      <RenderLastName data={data} formik={formik} showLastName={lastname === ''} />
      <RenderDocumentType data={data} formik={formik} showDocumentType={documentType === ''} />
      <RenderDocumentNumber data={data} formik={formik} showDocumentNumber={documentNumber === ''} />
      <RenderTaxType data={data} formik={formik} showTaxType={taxType === ''} />
      <RenderTaxNumber data={data} formik={formik} showTaxNumber={taxNumber === ''} />

      <ArticleStyled>
        <Input
          title={t(data).CVU.FORM.ALIAS}
          errorMessage={formik.errors.alias}
          onChange={formik.handleChange('alias')}
          onFocus={() => formik.setFieldTouched('alias', false)}
          touchedInput={formik.touched.alias}
          value={formik.values.alias}
          mask={createMask('ALIAS', 20)}
          placeholderChar={MASK_CHAR_CARD}
          guide={false}
          suffixIcon={true}
          maxLength={20}
        />
      </ArticleStyled>

      <ArticleStyled className="items-center">
        <SubmitStyled type="submit">{t(data).CVU.FORM.SEND}</SubmitStyled>
      </ArticleStyled>
    </FormCvuStyled>
  );
};

export { CvuForm };
