import React, { useEffect, useContext, memo } from 'react';

import { CreditcardContext } from '../../context/creditcard.context';
import { isUndefined, isTrue, isGreaterThan, diffTwoDatesInSeconds as diff } from '../../utils/functions';
import { navigateToError, redirectWhenStatusPageIsNotValid } from '../../utils/navigate';

import Layout from '../../components/responsive/layout';
import Topbar from '../../components/responsive/topbar';
import { Counter } from '../../components/counter';
import { CvuForm } from '../../components/forms/cvu-form';
import { LoadingComponent } from '../../components/loading';

import { TitleStyled, PStyled } from '../../styles/pages/cvu';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../context/lang.context';

const RenderLayout = memo(
  ({ render, get, token }: any) => {
    if (!render) {
      return <LoadingComponent />;
    }
    const { t } = useContext(LangContext);
    const data = useStaticQuery(graphql`
      query {
        allI18NJson {
          nodes {
            locale
            clientId
            CVU {
              TITLE_1
              TITLE_2
              TITLE_3
              DESCRIPTION
            }
          }
        }
      }
    `);

    return (
      <Layout>
        <Topbar />
        <section className="content">
          <TitleStyled>
            {t(data).CVU.TITLE_1}
            <span>{t(data).CVU.TITLE_2} </span>
            {t(data).CVU.TITLE_3}
          </TitleStyled>

          <Counter get={get} visible={false} height="0" paddingBottom="3" />

          <PStyled>{t(data).CVU.DESCRIPTION}</PStyled>

          <CvuForm get={get} token={token} />
        </section>
      </Layout>
    );
  },
  ({ render: prev }, { render: next }) => prev === next
);

const CvuPage = ({ get, getToken }: PageProps) => {
  const { infoUser, timeStamp, getCvu, loading } = useContext(CreditcardContext);
  const allValid = [Object.entries(infoUser).length, timeStamp?.length ?? 0].every(isGreaterThan(0));
  const token: string = getToken();

  useEffect(() => {
    if (!infoUser?.expirationTime && token) {
      getCvu?.(token);
    } else if (isUndefined(token)) {
      navigateToError()?.();
    }
  }, []);

  useEffect(() => {
    if (allValid) {
      redirectWhenStatusPageIsNotValid(infoUser, timeStamp);
    }
  }, [infoUser, timeStamp]);

  const dateDiff = diff(timeStamp ?? '', infoUser?.expirationTime ?? '');
  const render = [allValid, !!dateDiff, !infoUser.status, !loading].every(isTrue);
  return <RenderLayout render={render} get={get} token={token} />;
};

export default CvuPage;
